import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import DrawerHeader from '../Shared/DrawerHeader'
import DrawerButtons from '../Shared/DrawerButtons'
import { LockerReservationRow } from '../LockerReservation/LockerReservationTable'
import { Grid } from '@mui/material'
import { TextBody2Medium, TextBody2Bold } from '../Styles/TextCustom'
import styled from 'styled-components'
import FullScreenLoader from '../Shared/FullScreenLoader'
import LogsContainer from '../Shared/LogsContainer'
import { toast } from 'react-toastify'
import LeaseTermPickers from '../Lockers/LeaseTermPickers'
import TenantSelect from '../Shared/TenantSelect'
import LockerReservationStatusSelect from './UncontrolledReservationStatusSelect'
import {
  UPDATE_LOCKER_RESERVATION,
  IUpdateLockerReservationVars,
} from '../../graphql/lockers/updateLockerReservation'
import { ApolloError, useMutation } from '@apollo/client'
import { date2isoString } from '../../utils/formatDate'
import { ROUTES } from '../../const/routes'
import CancelLockerReservation from './CancelLockerReservation'
import ErrorContainer from '../Shared/ErrorContainer'
import { DetailsWrapper } from '../Styles/CustomElementsStyled'
import NewTabLink from '../Shared/NewTabLink'

const EditLockerReservation = ({
  closeDrawer,
  lockerReservation,
  refetch,
}: {
  closeDrawer: () => void
  lockerReservation?: LockerReservationRow
  refetch: () => void
}) => {
  const { t } = useTranslation(['lockers'])
  const successNotify = (message: string) => toast.dark(message)
  const [errorMessage, setErrorMessage] = useState('')

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm()

  const onCompleted = (data: any) => {
    successNotify(`${t('generic:success_message')}`)
    refetch()
    closeDrawer()
  }

  const [updateLockerReservation, { loading }] =
    useMutation<IUpdateLockerReservationVars>(UPDATE_LOCKER_RESERVATION, {
      onCompleted,
      onError: (error: ApolloError) => setErrorMessage(error.message),
    })

  if (!lockerReservation || loading) {
    return <FullScreenLoader />
  }
  const {
    id,
    reservationStatus,
    buildingName,
    name,
    key,
    transponder,
    type,
    tenant,
    tenantId,
    user,
    login,
    validFrom,
    validTo,
    reservation,
    leaseTerm,
  } = lockerReservation

  const onSubmit = (data: any) => {
    updateLockerReservation({
      variables: {
        id: reservation?.id,
        tenant: data.tenant ? { id: data.tenant.value } : undefined,
        status: data.status,
        validFrom: date2isoString(data.validFrom),
        validTo: date2isoString(data.validTo),
      },
    })
  }

  const details = [
    { label: t('building'), value: buildingName },
    {
      label: t('locker_type'),
      value: reservation?.order?.product.name || t(type),
    },
    { label: t('locker_number'), value: name },
    { label: t('key_number'), value: key },
    { label: t('transponder_number'), value: transponder },
    { label: t('user'), value: user },
    { label: 'Status', value: user && t(reservationStatus) },
    { label: t('lease_term'), value: user && leaseTerm },
  ]

  return (
    <>
      <DrawerHeader
        title={user ? t('reservation_details') : t('edit_reservation')}
        handleClose={closeDrawer}
      />
      <DetailsWrapper>
        {details.map(
          ({ label, value }) =>
            value &&
            value !== '-' && (
              <Row key={label}>
                <Text>{`${label}: `}</Text>
                <TextBold>{value}</TextBold>
              </Row>
            )
        )}
      </DetailsWrapper>
      <form onSubmit={handleSubmit(onSubmit)}>
        {!user && (
          <DetailsWrapper>
            <Wrapper>
              <LockerReservationStatusSelect
                control={control}
                errors={errors}
                defaultValue={reservationStatus}
              />
            </Wrapper>
            {validFrom && validTo && (
              <LeaseTermPickers
                control={control}
                errors={errors}
                defaultFrom={new Date(validFrom)}
                defaultTo={new Date(validTo)}
              />
            )}
            <Wrapper>
              <TenantSelect
                control={control}
                errors={errors}
                defaultValue={
                  tenantId && tenant
                    ? { value: tenantId, label: tenant }
                    : undefined
                }
              />
            </Wrapper>
          </DetailsWrapper>
        )}
        <DetailsWrapper>
          {reservation?.order?.orderId && (
            <NewTabLink
              path={`/admin/${ROUTES.LOCKER_PAYMENT_HISTORY}/id:${reservation?.order.orderId}`}
              text={t('payment_history:details_title')}
            />
          )}
          {/* 

          {/* <NewTabLink
            path={`/admin/${ROUTES.LOCKERS}/key:${key}`}
            text={t('go_to_edit_locker')}
          /> */}
        </DetailsWrapper>

        {user && (
          <CancelLockerReservation
            user={user}
            login={login}
            refetch={refetch}
            closeDrawer={closeDrawer}
          />
        )}
      </form>
      {!user && (
        <DrawerButtons
          handleAccept={handleSubmit(onSubmit)}
          handleCancel={closeDrawer}
        />
      )}

      {errorMessage && <ErrorContainer errorMessage={errorMessage} />}
      <DetailsWrapper>
        <LogsContainer logs={reservation?.logs} />
      </DetailsWrapper>
    </>
  )
}

export default EditLockerReservation
const Row = styled(Grid)`
  padding: 2px;
`
const Text = styled(TextBody2Medium)`
  display: inline;
`
const TextBold = styled(TextBody2Bold)`
  display: inline;
`
const Wrapper = styled(Grid)`
  padding: 16px 0;
`
