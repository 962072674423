import {
  useMemo,
  useState,
  FC,
  ReactNode,
  useLayoutEffect,
  useCallback,
} from 'react'
import { useTranslation } from 'react-i18next'
import {
  dateIso2localeString,
  dateIso2ExcelFormat,
} from '../../utils/formatDate'
import BaseDrawer from '../Shared/BaseDrawer'
import { getLockerStatusColor } from '../../utils/getStatusColor'
import EditManyLockers from './EditManyLockers'
import EditLocker from './EditLocker'
import {
  LockerType,
  ILocker,
  LockerStatus,
} from '../../graphql/lockers/queryLockers'
import { getCurrentActiveReservations } from '../../utils/getCurrentReservation'
import { IReservation } from '../../graphql/shared/sharedTypes'
import { IBuilding } from '../../graphql/lockers/queryLockerGroupReservation'
import { useNavigate } from 'react-router-dom'
import LockerTableActions from '../Lockers/LockerTableActions'
import ReservationSummary from '../LockerProtocols/ReservationSummary'
import styled from 'styled-components'
import TooltipButton from '../Shared/TooltipButton'
import { Grid } from '@mui/material'
import { TextBody1Bold } from '../../components/Styles/TextCustom'
import { themeColors } from '../../const/colors'
import { useAppSelector } from '../../redux/store'
import { checkRole } from '../../utils/checkRole'
import { LOCKER_ADMIN } from '../../const/permissions'
import Table from '../../components/Table'
import { ColumnDef } from '@tanstack/react-table'
import { handleRouteColumnFilter } from '../../utils/handleRouteColumnFilter'
import { useParams } from 'react-router-dom'

export interface Row {
  id: string
  buildingName: string
  building: IBuilding
  name: string
  key: string
  transponder: string
  status: LockerStatus
  leaseTerm?: string
  tenant?: string
  user?: string
  userData?: {
    email: {
      email: string
    }
    firstName: string
    lastName: string
    login: string
    tenant?: {
      name: string
    }
  }
  notes?: string
  type: LockerType
  lockerName: string
  validFrom?: string
  validTo?: string
  email?: string
  logs?: {
    createdAt: string
    message: string
  }[]
  reservation?: IReservation
  reservations: IReservation[]
  createdAt?: string
}

const LockersTable: FC<{
  lockers: ILocker[]
  children: ReactNode
  refetch: () => void
  lockerId?: string
  status: LockerStatus | string
}> = ({ lockers, children, refetch, lockerId, status }) => {
  const { t, i18n } = useTranslation(['lockers'])
  const lang = i18n.language
  const [editLocker, setEditLocker] = useState<Row>()
  const [editLockers, setEditLockers] = useState<Row[]>([])
  const [openedProtocol, setOpenedProtocol] = useState<Row[]>([])
  const [pickUpProtocol, setPickUpProtocol] = useState(false)
  const [selectedRows, setSelectedRows] = useState<Row[]>([])
  const navigate = useNavigate()
  const { roles } = useAppSelector((state) => state.user)
  const { params } = useParams()

  const handleCloseDrawer = () => {
    setEditLocker(undefined)
    navigate('/admin/lockers/:status', { state: {}, replace: true })
  }
  const handleCloseProtocol = () => {
    setPickUpProtocol(false)
    setOpenedProtocol([])
  }

  const handleSelectRows = useCallback((selectedRowsData: Row[]) => {
    setSelectedRows(selectedRowsData)
  }, [])

  const handleOpenChangeStatusForm = () => setEditLockers(selectedRows)

  const invisibleColumns = {
    id: false,
    type: false,
    createdAt: false,
    notes: false,
    actions: checkRole(roles, LOCKER_ADMIN),
  }

  const csvHeaders = [
    {
      label: 'ID',
      key: 'id',
    },
    {
      label: t('building'),
      key: 'buildingName',
    },
    {
      label: t('locker_number'),
      key: 'name',
    },
    {
      label: t('key_number'),
      key: 'key',
    },
    {
      label: t('transponder_number'),
      key: 'transponder',
    },
    {
      label: 'Status',
      key: 'status',
    },
    {
      label: t('tenant'),
      key: 'tenant',
    },
  ]

  const columns: ColumnDef<Row, any>[] = useMemo(
    () => [
      {
        accessorKey: 'id',
        header: 'ID',
      },
      {
        accessorKey: 'buildingName',
        header: t('building'),
      },
      {
        accessorKey: 'name',
        header: t('locker_number'),
      },
      {
        accessorKey: 'key',
        header: t('key_number'),
      },
      {
        accessorKey: 'transponder',
        header: t('transponder_number'),
      },
      {
        accessorKey: 'lockerName',
        header: t('locker_type'),
      },
      {
        accessorKey: 'type',
        header: t('locker_type'),
      },
      {
        accessorKey: 'status',
        header: t('locker_status'),
        cell: (value) => t(`locker_status:${value.getValue()}`),
      },
      {
        accessorKey: 'leaseTerm',
        header: t('current_lease'),
        enableColumnFilter: false,
      },
      {
        accessorKey: 'tenant',
        header: t('tenant'),
      },
      {
        accessorKey: 'user',
        header: t('user'),
      },
      {
        accessorKey: 'createdAt',
        header: t('created_at'),
        cell: (value) => value && dateIso2ExcelFormat(value.getValue()),
      },
      {
        accessorKey: 'notes',
        header: t('notes'),
      },
      {
        accessorKey: 'actions',
        header: '',
        cell: (value) => (
          <LockerTableActions
            data={value.row.original}
            setOpenedProtocol={setOpenedProtocol}
            setPickUpProtocol={setPickUpProtocol}
          />
        ),
        enableColumnFilter: false,
      },
    ],
    [t]
  )

  const tableData: Row[] = useMemo(() => {
    return (
      lockers.map(
        ({
          id,
          name,
          status,
          type,
          building,
          transponder,
          key,
          notes,
          reservations,
          logs,
          currentTenant,
          currentUser,
        }) => {
          const currentReservation: IReservation | undefined =
            status === LockerStatus.should_be_returned
              ? reservations[0]
              : getCurrentActiveReservations(reservations)[0]
          return {
            id,
            buildingName: building.name,
            building,
            name,
            key,
            transponder,
            status,
            leaseTerm: currentReservation
              ? `${dateIso2localeString(currentReservation.validFrom, lang, {
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                })} - ${dateIso2localeString(currentReservation.validTo, lang, {
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                })}`
              : '-',
            email: currentReservation?.user
              ? currentReservation?.user?.email?.email
              : currentReservation?.tenant?.emails[0]?.email,
            validFrom: currentReservation?.validFrom,
            validTo: currentReservation?.validTo,
            tenant: currentReservation ? currentReservation.tenant?.name : '-',
            user: currentReservation?.user
              ? `${currentReservation.user?.firstName} ${currentReservation.user?.lastName}`
              : '',
            userData: currentReservation?.user,
            notes,
            type,
            lockerName: currentReservation?.order
              ? currentReservation.order.product.name
              : t(type),
            logs,
            reservation: currentReservation,
            reservations,
            createdAt: currentReservation?.createdAt,
          }
        }
      ) || []
    )
  }, [lockers, lang, t])

  useLayoutEffect(() => {
    if (lockerId) {
      setEditLocker(tableData.find(({ id }) => id === lockerId))
    }
  }, [lockerId, tableData])

  return (
    <>
      <Table
        columns={columns}
        data={tableData}
        columnVisibility={invisibleColumns}
        enableRowSelection
        csvExport
        onSelectionChange={handleSelectRows}
        csvHeaders={csvHeaders}
        onRowClick={(rowData: Row) => {
          checkRole(roles, LOCKER_ADMIN) && rowData && setEditLocker(rowData)
        }}
        getCellProps={({ column, row }) =>
          column.id === 'status'
            ? {
                style: {
                  color: getLockerStatusColor(row.original.status),
                  fontWeight: 600,
                },
              }
            : {}
        }
        sortByKey="createdAt"
        sortDesc
        defaultColumnFilter={params ? handleRouteColumnFilter(params) : []}
      >
        {children}
        <RowContainer>
          {!!selectedRows.length && (
            <Text color={themeColors.primary}>{`${selectedRows.length} ${t(
              'generic:selected'
            )}`}</Text>
          )}
          {checkRole(roles, LOCKER_ADMIN) && (
            <TooltipButton
              onClick={handleOpenChangeStatusForm}
              disabled={status === 'all' || !selectedRows.length}
              hint={t('change_status_button_hint')}
              data-e2e="change-status"
            >
              {t('change_status')}
            </TooltipButton>
          )}
        </RowContainer>
      </Table>
      <BaseDrawer
        open={!!editLocker || !!openedProtocol.length || !!editLockers.length}
        variant={'temporary'}
      >
        {!!editLockers.length && (
          <EditManyLockers
            closeDetails={() => setEditLockers([])}
            lockers={editLockers}
            refetch={refetch}
            lockerStatus={status}
          />
        )}
        {!!editLocker && (
          <EditLocker
            closeDetails={handleCloseDrawer}
            locker={editLocker}
            refetch={refetch}
          />
        )}
        {!!openedProtocol.length && (
          <ReservationSummary
            closeDrawer={handleCloseProtocol}
            reservations={openedProtocol}
            pickUp={pickUpProtocol}
          />
        )}
      </BaseDrawer>
    </>
  )
}

export default LockersTable

const RowContainer = styled(Grid)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 16px;
`
const Text = styled(TextBody1Bold)`
  margin-right: 16px;
`
